@use './colors' as *;
@use './mixins' as *;

$font-stack: 'Poppins', 'DM Sans', sans-serif;

h1 {
  color: $heading;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: to-rem(40px);
  @media (min-width: 600px) {
    font-size: to-rem(58px);
  }
  letter-spacing: normal;
  line-height: 1.22;
  text-transform: none;
}

h2 {
  color: $heading;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: to-rem(32px);
  @media (min-width: 600px) {
    font-size: to-rem(52px);
  }
  letter-spacing: normal;
  line-height: 1.2;
  text-transform: none;
}

h3 {
  color: $heading;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: to-rem(28px);
  @media (min-width: 600px) {
    font-size: to-rem(38px); // 38px
  }
  letter-spacing: normal;
  line-height: 1.2;
  text-transform: none;
}

/* Since there is no existing spec for an h5; 
we duplicate the h4 so that if used; the h5 will not use Material-UI's
default font which is outide of Inclusivey branding */

h4,
h5 {
  color: $heading;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: to-rem(21px);
  letter-spacing: normal;
  line-height: 1.5;
  text-transform: none;
}

h6 {
  color: $heading;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: to-rem(20px);
  letter-spacing: normal;
  line-height: 1.2;
  text-transform: none;
}

a {
  color: $primary;
  &:hover {
    color: $primary-light;
  }
  &:focus {
    color: $primary;
    background-color: $disabled;
  }
  &:visited {
    color: $primary-dark;
  }
}

//Material-UI Typography Component Configuration

.MuiTypography-root {
  font-family: $font-stack;

  &.MuiTypography-h1 {
    @extend h1;
  }

  &.MuiTypography-h2 {
    @extend h2;
  }

  &.MuiTypography-h3 {
    @extend h3;
  }

  &.MuiTypography-h4,
  &.MuiTypography-h5 {
    @extend h4;
  }

  &.MuiTypography-h6 {
    @extend h6;
  }

  &.MuiTypography-subtitle1 {
    font-family: 'DM Sans';
    font-weight: 400;
    font-size: to-rem(20px);
    letter-spacing: normal;
    line-height: 1.2;
    @media (min-width: 600px) {
      font-size: to-rem(24px);
      line-height: 1.46;
    }
  }

  &.MuiTypography-body1 {
    color: $body;
    font-family: 'DM Sans';
    font-size: to-rem(18px);
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.5;
    text-transform: none;
  }

  &.MuiTypography-body2 {
    color: $body;
    font-family: 'DM Sans';
    font-size: to-rem(16px);
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.5;
    text-transform: none;
  }

  &.MuiTypography-button {
    font-family: 'DM Sans';
    font-size: to-rem(16px);
    font-weight: 500;
    letter-spacing: normal;
    text-transform: none;
  }
}
