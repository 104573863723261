// variable names courtesy of https://chir.ag/projects/name-that-color/

//Base Colors
$alto: #cfcfcf;
$black: #000;
$blue-chalk: #f2e9ff;
$cherry-pie: #2e0160;
$cinnabar: #ec4343;
$ebony-clay: #22223b;
$electric-violet: #6f00f8;
$fiord: #494f69;
$fog: #dbc3fd;
$magnolia: #f9f5ff;
$purple-heart: #6420c4;
$river-bed: #4a4f69;
$salem: #04844b;
$spray: #7aefe1;
$titan-white: #ebebff;
$whisper: #eeeef5;
$white-ice: #e4faf7;
$white-lilac: #f2f4fa;
$white: #fff;
$yellow-orange: #ffbf47;

//Color Mappings

$body: $fiord;
$heading: $ebony-clay;

$input-gray: rgba($black, 0.43);

$success: $salem;
$error: $cinnabar;
$warning: $yellow-orange;
$disabled: $alto;

$primary: $electric-violet;
$primary-light: #8936ff; //hex value used here because this value is still considered "Electric Violet"
$primary-dark: $cherry-pie;

$secondary: $spray;
$secondary-light: $white-ice;

//Exported color variables will be available in React

:export {
  black: $black;
  blueChalk: $blue-chalk;
  cinnabar: $cinnabar;
  disabled: $disabled;
  ebonyClay: $ebony-clay;
  electricViolet: $electric-violet;
  error: $error;
  fiord: $fiord;
  fog: $fog;
  inputGray: $input-gray;
  magnolia: $magnolia;
  purpleHeart: $purple-heart;
  riverBed: $river-bed;
  salem: $salem;
  spray: $spray;
  success: $success;
  titanWhite: $titan-white;
  warning: $warning;
  whisper: $whisper;
  white: $white;
  whiteLilac: $white-lilac;
  yellowOrange: $yellow-orange;
}
