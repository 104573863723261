@use 'styles/_colors' as *;
@use 'styles/_typography';
@use 'styles/_mixins';

@import '~react-image-gallery/styles/css/image-gallery.css';

html,
body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}

#root {
  height: 100%;
  overflow-x: hidden;
  background-color: $white;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
}

.skip-link {
  position: absolute;
  top: -100px;
  left: 50%;
  padding: 0.5rem 1rem;
  color: $white;
  font-size: 1rem;
  background-color: $electric-violet;
  border-radius: 8px;
  box-shadow: 0px 3px 1px -2px rgba($black, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba($black, 0.12);
  outline: 0;
  transform: translateX(-50%);
  transition: 0.2s ease;
  z-index: 6;
}
.skip-link:focus {
  top: 20px;
}

.MuiAutocomplete-popper {
  width: auto !important;
}
