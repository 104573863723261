@use '../../styles/_colors' as *;

.root {
  background-color: $titan-white;
}
.wrapper {
  padding: 2rem 1.5rem;
}
.form-container {
  margin-top: 1.5rem;
}
.title {
  color: $electric-violet !important;
}
